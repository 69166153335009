import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import heroSuperService from "./heroSuperService";

const initialState = {
  heroSuper: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  uploadSuccess: false
};
export const createHeroSuper = createAsyncThunk(
  "create/heroSuper",
  async (heroSuperData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await heroSuperService.createHeroSuper(heroSuperData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
        if(error.response.status === 401){
          console.log(error.response.status);
        }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getHeroSuper = createAsyncThunk("get/heroSuper", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await heroSuperService.getHeroSuper(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
// export const deleteGoals = createAsyncThunk(
//   "delete/goals",
//   async (id, thunkAPI) => {
//     try {
//       const token = thunkAPI.getState().auth.user.token;
//       return await goalService.deleteGoals(id, token);
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );
export const heroSuperSlice = createSlice({
  name: "heroSuperSlice",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createHeroSuper.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createHeroSuper.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.uploadSuccess = true;
        state.heroSuper = [action.payload];
      })
      .addCase(createHeroSuper.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getHeroSuper.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHeroSuper.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.heroSuper = action.payload;
      })
      .addCase(getHeroSuper.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // .addCase(deleteGoals.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(deleteGoals.fulfilled, (state, action) => {
    
      //   state.isLoading = false;
      //   state.isSuccess = true;
      //   state.goals = state.goals.filter(
      //     (goal) => goal._id !== action.payload.id
      //   );
      // })
      // .addCase(deleteGoals.rejected, (state, action) => {
      //   state.isLoading = false;
      //   state.isError = true;
      //   state.message = action.payload;
      // });
  },
});
export const { reset } = heroSuperSlice.actions;
export default heroSuperSlice.reducer;
