import axios from "axios";
import { constants } from "../../app/constants";

const API_URL = constants.REACT_APP_API_BASE_URL;

const updateDownloadLink = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "/downloadLink", data, config);

  return response.data;
};
const getDownloadLink = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "/downloadLink", config);

  return response.data;
};

const downloadLinkService = { updateDownloadLink, getDownloadLink };
export default downloadLinkService;
