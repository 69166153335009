import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, PageHeader, message, Upload, Space, Image, Row, Col, Divider, Spin } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { createHeroSuper, getHeroSuper, reset } from "../features/homeFeatures/heroSuperSlice";
const { Dragger } = Upload;


function HeroSuper() {
  const dispatch = useDispatch();
  const [imgList, setImgList] = useState([]);
  const { heroSuper, isLoading, uploadSuccess, isError } = useSelector(
    (state) => state.heroSuper
  );
  useEffect(() => {
    dispatch(reset());
    dispatch(getHeroSuper());
    // console.log(heroSuper)
  }, []);

  useEffect(() => {
    if (uploadSuccess) {
      message.success(`Image uploaded successfully.`);
    }
    if (isError) {
      message.error(`Image upload error.`);
    }
  }, [uploadSuccess, isError]);

  const props = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    beforeUpload: (file) => {
      // setImgList([...imgList, file]);
      setImgList([file]);
      console.log(imgList)
      return false;
    },
    onRemove: () => {
      // const index = imgList.indexOf(file);
      // const newFileList = imgList.slice();
      // newFileList.splice(index, 1);
      // setImgList(newFileList);
      setImgList([]);
      console.log(imgList)
    },
    imgList,
    listType: "picture-card",
    async onPreview(file) {
      let src = file.thumbUrl;
      console.log(file)
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      const imgWindow = window.open(src);
      imgWindow?.document.write(image.outerHTML);
    }

  };

  const handleUpload = () => {
    const formData = new FormData();
    // imgList.forEach((file) => {
    //   formData.append('image', file);
    // });
    formData.append('image', imgList[0]);
    dispatch(createHeroSuper(formData));
    setImgList([]);

  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="Hero Super"
        subTitle="This section is a home page hero super."
      />
      <Row>
        <Col span={12}>
          <Spin spinning={isLoading}>
            <Space>
              {heroSuper.length > 0 ?
                <Image
                  width={"95%"}
                  src={heroSuper[0].img}
                  preview={{
                    src: heroSuper[0].img,
                  }}
                  style={{ boxShadow: 'gray 1px 1px 2px 1px' }}
                /> :
                <Image
                  width={"95%"}
                  src="/image.jpg"
                />}
            </Space>
          </Spin>
        </Col>
        {/* <Col span={1}>
          <Divider type="vertical" />
        </Col> */}
        <Col span={12}>
          <Space style={{ display: 'block' }}>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single upload. Strictly prohibit from uploading company data or other
                band files
              </p>
            </Dragger>
          </Space>
          <Space style={{ display: 'block' }}>
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={imgList.length === 0}
              loading={isLoading}
              style={{ marginTop: 16 }}
              block
            >
              {isLoading ? 'Setting' : 'Set Image'}
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  )
}

export default HeroSuper;