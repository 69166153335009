import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./pages/Register";
import NoPage from "./pages/NoPage";
import LayoutCon from "./components/Layout";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import Blog from "./pages/Blog";
import Login from "./pages/Login";
import AuthVerify from "./features/auth/authVerify";
import HeroSuper from "./pages/HeroSuper";
import WhatSN from "./pages/WhatSN";
import WhySN from "./pages/WhySN";
import PopularBooks from "./pages/PopularBooks";
import Feedbacks from "./pages/Feedbacks";
import Subscription1 from "./pages/Subscription1";
import Subscription2 from "./pages/Subscription2";
import Subscription3 from "./pages/Subscription3";
// import Subscription4 from "./pages/Subscription4";
import HowToPay from "./pages/HowToPay";
import HowToCoupon from "./pages/HowToCoupon";
import HowToRead from "./pages/HowToRead";
import Setting from "./pages/Setting";
import Promotion from "./pages/Promotion";
import DownloadLink from "./pages/DownloadLink";
import Features from "./pages/Features";

function App() {
  const logOut = () => {
    localStorage.removeItem("user");
    window.history.go('login');
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutCon />}>
          <Route index element={<HeroSuper />} />
          <Route path="register" element={<Register />} />
          <Route path="home" element={<Home />} />
          <Route path="hero-super" element={<HeroSuper />} />
          <Route path="features" element={<Features />} />
          <Route path="what-sn" element={<WhatSN />} />
          <Route path="why-sn" element={<WhySN />} />
          <Route path="popular-books" element={<PopularBooks />} />
          <Route path="feedbacks" element={<Feedbacks />} />
          <Route path="pricing" element={<Pricing />} />
          <Route path="sub1" element={<Subscription1 />} />
          <Route path="sub2" element={<Subscription2 />} />
          <Route path="sub3" element={<Subscription3 />} />
          {/* <Route path="sub4" element={<Subscription4 />} /> */}
          <Route path="howtopay" element={<HowToPay />} />
          <Route path="howtocoupon" element={<HowToCoupon />} />
          <Route path="howtoread" element={<HowToRead />} />
          <Route path="blog" element={<Blog />} />
          <Route path="setting" element={<Setting />} />
          <Route path="promotion" element={<Promotion />} />
          <Route path="downloadLink" element={<DownloadLink />} />
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="login" element={<Login />} />
      </Routes>
      <Routes>
      </Routes>
      <AuthVerify logOut={logOut}/>
    </BrowserRouter>
  );
}

export default App;
