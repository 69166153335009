import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import settingsService from "./settingsService";

const initialState = {
  settings: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};
// Settings
export const updatePromotionMenu = createAsyncThunk(
  "settings/promotion",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await settingsService.updatePromotionMenu(data,  token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error.response.status === 401) {
        console.log(error.response.status);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSettings = createAsyncThunk("get/settings", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await settingsService.getSettings(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// export const updateBlog = createAsyncThunk(
//   "settings",
//   async (data, thunkAPI) => {
//     try {
//       const token = thunkAPI.getState().auth.user.token;
//       return await settingsService.updateBlog(data.blogId, data, token);
//     } catch (error) {
//       const message =
//         (error.response &&
//           error.response.data &&
//           error.response.data.message) ||
//         error.message ||
//         error.toString();
//       if (error.response.status === 401) {
//         console.log(error.response.status);
//       }
//       return thunkAPI.rejectWithValue(message);
//     }
//   }
// );

// export const deleteBlog = createAsyncThunk("delete/blog", async (blogId, thunkAPI) => {
//   try {
//     const token = thunkAPI.getState().auth.user.token;
//     return await settingsService.deleteBlog(blogId, token);
//   } catch (error) {
//     const message =
//       (error.response && error.response.data && error.response.data.message) ||
//       error.message ||
//       error.toString();
//     return thunkAPI.rejectWithValue(message);
//   }
// });

export const settingsSlice = createSlice({
  name: "settingsSlice",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updatePromotionMenu.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePromotionMenu.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.settings = action.payload;
      })
      .addCase(updatePromotionMenu.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.settings = action.payload;
      })
      .addCase(getSettings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});
export const { reset } = settingsSlice.actions;
export default settingsSlice.reducer;
