import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  PageHeader,
  message,
  Space,
  Form,
  List,
  Divider,
  Row,
  Col,
  Select,
  Spin,
  DatePicker,
  Input,
  Card,
} from "antd";
import {
  InboxOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  updateDownloadLink,
  getDownloadLink,
  reset,
} from "../features/downloadLink/downloadLinkSlice";

const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};


function DownloadLink() {
  const dispatch = useDispatch();
  const [promoPackage, setPromoPackage] = useState("001");
  const { downloadLink, isLoading, isSuccess, isError } = useSelector(
    (state) => state.downloadLink
  );
  useEffect(() => {
    dispatch(reset());
    dispatch(getDownloadLink());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      message.success(`Updated successfully.`);
    }
    if (isError) {
      message.error(`Update error.`);
    }
  }, [isSuccess, isError]);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setPromoPackage(value);
    // console.log(promoPackage)
  };

  const onFinish = async (values) => {
    console.log("Success:", values);
    await dispatch(
      updateDownloadLink({
        downloadLink: values.downloadLink,
      })
    );
    dispatch(getDownloadLink());
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="Download Link"
        subTitle="This section is to set download link."
      />
      <Row>
        <Col md={12} sm={24} style={{ marginBottom: "20px" }}>
          <Card title="Download Link">
          <Form
              name="dynamic_form_item"
              {...formItemLayoutWithOutLabel}
              onFinish={onFinish}
            >
              <Form.Item
                label="Link"
                name="downloadLink"
                rules={[
                  {
                    required: true,
                    message: "Please input download link!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  // disabled={imgList.length === 0}
                  loading={isLoading}
                >
                  {isLoading ? "Submitting" : "Submit"}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col md={12} sm={24}>
        <Spin spinning={isLoading}>
            <Space width={"99%"}>
              <Card>
                {downloadLink ? (
                  <div>
                    {downloadLink.downloadLink !== undefined ? (
                      <>
                        <Divider orientation="left">Download Link</Divider>
                        <List
                          bordered
                          dataSource={[downloadLink.downloadLink]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </>
                    ) : null}
                    
                  </div>
                ) : (
                  <div>Loading...</div>
                )}
              </Card>
            </Space>
          </Spin>
          {/* <Spin spinning={isLoading}>
            <Space width={"99%"}>
              <Card>
                {promotion ? (
                  <div>
                    {promotion.packageDoc &&
                    promotion.packageDoc.description !== undefined ? (
                      <>
                        <Divider orientation="left">Promotion Package</Divider>
                        <List
                          bordered
                          dataSource={[promotion.packageDoc.description]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </>
                    ) : null}
                    {promotion.promotionDoc &&
                    promotion.promotionDoc.endDate !== undefined ? (
                      <>
                        <Divider orientation="left">End Date</Divider>
                        <List
                          bordered
                          dataSource={[promotion.promotionDoc.endDate]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </>
                    ) : null}
                    {promotion.promotionDoc &&
                    promotion.promotionDoc.description !== undefined ? (
                      <>
                        <Divider orientation="left">Description</Divider>
                        <List
                          bordered
                          dataSource={[promotion.promotionDoc.description]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </>
                    ) : null}
                  </div>
                ) : (
                  <div>Loading...</div>
                )}
              </Card>
            </Space>
          </Spin> */}
        </Col>
      </Row>
    </div>
  );
}

export default DownloadLink;
