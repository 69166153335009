import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { PageHeader, message, Space, Row, Col, Switch, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { updatePromotionMenu, getSettings, reset } from "../features/settings/settingsSlice";

function Setting() {
  const dispatch = useDispatch();
  const { settings, isLoading, isSuccess, isError } = useSelector(
    (state) => state.settings
  );
  useEffect(() => {
    dispatch(reset());
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      message.success(`Updated status successfully.`);
    }
    if (isError) {
      message.error(`Update error.`);
    }
  }, [isSuccess, isError]);

  const onChangePromotion = (checked) => {
    console.log(`switch to ${checked}`);
    dispatch(updatePromotionMenu({ showPromotionMenu: checked }));
  };

  // const onFinish = (values) => {
  //   console.log('Success:', values);
  // };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="Settings"
        subTitle="This section is to set page setting."
      />
      <Row>
        <Col span={12}>
          <Spin spinning={isLoading}>
            <Space>
              Promotion Menu : <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />}
                checked={settings.showPromotionMenu}
                onChange={onChangePromotion} />
            </Space>
          </Spin>
        </Col>
      </Row>
    </div>
  )
}

export default Setting;