import axios from "axios";
import { constants } from "../../app/constants";

const API_URL = constants.REACT_APP_API_BASE_URL;

const updatePromotion = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "/promotion", data, config);

  return response.data;
};
const getPromotion = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "/promotion", config);

  return response.data;
};

const promotionService = { updatePromotion, getPromotion };
export default promotionService;
