import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import feedbacksService from "./feedbacksService";

const initialState = {
  feedbacks: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  uploadSuccess: false
};
export const createFeedbacks = createAsyncThunk(
  "create/feedbacks",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await feedbacksService.createFeedbacks(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error.response.status === 401) {
        console.log(error.response.status);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getFeedbacks = createAsyncThunk("get/feedbacks", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await feedbacksService.getFeedbacks(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
export const feedbacksSlice = createSlice({
  name: "feedbacksSlice",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFeedbacks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createFeedbacks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.uploadSuccess = true;
        state.feedbacks = action.payload;
      })
      .addCase(createFeedbacks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getFeedbacks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFeedbacks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.feedbacks = action.payload;
      })
      .addCase(getFeedbacks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});
export const { reset } = feedbacksSlice.actions;
export default feedbacksSlice.reducer;
