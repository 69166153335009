import * as React from 'react';
import { Button } from 'antd';

function Pricing() {
  return (
    <div>
      <Button type="primary">Pricing</Button>
    </div>
  )
}

export default Pricing;