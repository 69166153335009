import { configureStore } from "@reduxjs/toolkit";
import heroSuperSlice from "../features/homeFeatures/heroSuperSlice";
import authReducer from "./../features/auth/authSlice";
import whatSNSlice from "../features/homeFeatures/whatSNSlice";
import whySNSlice from "../features/homeFeatures/whySNSlice";
import popularBooksSlice from "../features/homeFeatures/popularBooksSlice";
import feedbacksSlice from "../features/homeFeatures/feedbacksSlice";
import pricingSlice from "../features/pricing/pricingSlice";
import blogSlice from "../features/blog/blogSlice";
import settingsSlice from "../features/settings/settingsSlice";
import promotionSlice from "../features/promotion/promotionSlice";
import downloadLinkSlice from "../features/downloadLink/downloadLinkSlice";
import featuresSlice from "../features/homeFeatures/shwenote_featuresSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    heroSuper: heroSuperSlice,
    features: featuresSlice,
    whatSN: whatSNSlice,
    whySN: whySNSlice,
    popularBooks: popularBooksSlice,
    feedbacks: feedbacksSlice,
    pricing: pricingSlice,
    blog: blogSlice,
    settings: settingsSlice,
    promotion: promotionSlice,
    downloadLink: downloadLinkSlice
  },
});
