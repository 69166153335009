import axios from "axios";
import { constants } from "../../app/constants";

const API_URL = constants.REACT_APP_API_BASE_URL + "/hero-super/post";
const API_URL_GET = constants.REACT_APP_API_BASE_URL + "/hero-super/all";

const createHeroSuper = async (heroSuperData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL, heroSuperData, config);

  return response.data;
};
const getHeroSuper = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL_GET, config);

  return response.data;
};
// const deleteGoals = async (goalId, token) => {
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   const response = await axios.delete(API_URL + goalId, config);
//   return response.data;
// };

const heroSuperService = { createHeroSuper, getHeroSuper };
export default heroSuperService;
