import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, PageHeader, message, Upload, Space, Image, Row, Col, Modal, Divider, Table, Form, Input, Radio, Popconfirm, Spin } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, FileAddOutlined } from '@ant-design/icons';
import { createBlog, getBlog, deleteBlog, reset, updateBlog } from "../features/blog/blogSlice";

const { TextArea } = Input;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function Blog() {
  const dispatch = useDispatch();
  const [isUpdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [value, setValue] = useState("video");
  const [updateBlogId, setUpdateBlogId] = useState("");
  const [imgList, setImgList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [isUpdateUrl, setIsUpdateUrl] = useState(true);
  const { blog, isLoading, isSuccess, uploadSuccess, isError } = useSelector(
    (state) => state.blog
  );

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text) => <span>{text.length > 20 ? text.slice(0, 20) + "..." : text}</span>,
      responsive: ['md'],
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => <span>{text.length > 20 ? text.slice(0, 20) + "..." : text}</span>,
      responsive: ['md'],
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      responsive: ['md'],
    },
    {
      title: 'Url',
      dataIndex: 'url',
      key: 'url',
      render: (text) => <span>{text.length > 30 ? text.slice(0, 30) + "..." : text}</span>,
      responsive: ['md'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="large">
          <EditOutlined
            style={{ color: "green" }}
            onClick={() => handleEdit(record)}
          />
          <Popconfirm
            title="Are you sure to delete this blog?"
            onConfirm={() => handleDelete(record)}
            onCancel={cancelDelete}
            okText="Yes"
            cancelText="No"
            placement="left"
          >
            <DeleteOutlined
              style={{ color: "red" }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const props = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      setImgList([file]);
      setIsUpdateUrl(true);
      // console.log(imgList)
      return false;
    },
    onRemove: () => {
      setImgList([]);
      // console.log(imgList)
    },
    fileList: imgList,
    listType: "picture-card",
    async onPreview(file) {
      console.log(file)
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
    },
  };

  useEffect(() => {
    dispatch(reset());
    dispatch(getBlog());
  }, []);

  useEffect(() => {
    if (uploadSuccess) {
      message.success(`Blog created successfully.`);
    }
    if (isError) {
      message.error(`Operation failed.`);
    }
    if (isSuccess) {
      message.success(`Operation success.`);
    }
  }, [isSuccess, isError]);

  const handleEdit = (blog) => {
    setIsUpdate(true);
    setUpdateBlogId(blog._id);
    form.setFieldsValue({
      title: blog.title,
      description: blog.description,
      duration: blog.duration,
      url: blog.url,
      youtubeUrl: blog.youtubeUrl,
      isVideo: blog.isVideo,
      modifier: blog.isVideo ? "video" : "image"
    });
    setValue(blog.isVideo ? "video" : "image");
    setImgList([
      {
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: blog.url,
      },
    ]);
    setOpen(true);
  };

  const handleDelete = (blog) => {
    dispatch(deleteBlog(blog._id));
  }

  const onCreate = (values) => {
    console.log('Received values of form: ', values);
    setOpen(false);
    const formData = new FormData();
    // if(isUpdateUrl === false){
      formData.append('image', values.image.file);
    // }
    
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('duration', values.duration);
    formData.append('youtubeUrl', values.youtubeUrl || "");

    if (values.modifier === "video") {
      formData.append('isVideo', true);
    } else {
      formData.append('isVideo', false);
    }

    if (isUpdate === true) {
      formData.append('blogId', updateBlogId);
      formData.append('isUpdateUrl', isUpdateUrl);
      dispatch(updateBlog(formData));
    } else {
      formData.append('isUpdateUrl', isUpdateUrl);
      dispatch(createBlog(formData));
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setIsUpdate(false);
    setValue("video");
    form.setFieldsValue({
      title: "",
      description: "",
      duration: "",
      url: "",
      youtubeUrl: "",
      isVideo: true,
      modifier: "video"
    });
  }
  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };

  const handleCancelPreview = () => setPreviewOpen(false);
  const cancelDelete = (e) => {
    console.log(e);
    message.error('Delete cancel');
  };

  return (
    <div>

      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="Blogs"
        subTitle="This section is a blog page."
        extra={[<Button type='primary' onClick={() => {
          setOpen(true);
        }}>Add New</Button>]}
      />
      <Spin spinning={isLoading}>
        <Table columns={columns} dataSource={blog} rowKey={record => record._id} />
      </Spin>
      <Modal
        open={open}
        title={isUpdate ? "Update Blog" : "Create a new blog"}
        okText="Save"
        cancelText="Cancel"
        onCancel={handleCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
              setValue("video");
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
        width={1000}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "video"
          }}
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: 'Please input the title of blog!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Duration" name="duration"
            rules={[
              {
                required: true,
                message: 'Please input the duration of blog!',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description"
            rules={[
              {
                required: true,
                message: 'Please input the description of blog!',
              },
            ]}>
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item name="modifier">
            <Radio.Group onChange={onChange} value={value}>
              <Radio value="video">Video</Radio>
              <Radio value="image">Image</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="image" label="Image" className="collection-create-form_last-form-item"
            rules={[
              {
                required: true,
                message: 'Please upload the photo of blog!',
              },
            ]}>
            <Upload {...props}>
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Upload
                </div>
              </div>
            </Upload>
          </Form.Item>
          {value === "video" ?
            <Form.Item name="youtubeUrl" label="Youtube Url" className="collection-create-form_last-form-item"
              rules={[
                {
                  required: true,
                  message: 'Please input the youtube url of blog!',
                },
              ]}>
              <Input />
            </Form.Item>
            :
            null
          }
        </Form>
        <Modal open={previewOpen} footer={null} onCancel={handleCancelPreview}>
          <img
            alt="example"
            style={{
              width: '100%',
            }}
            src={previewImage}
          />
        </Modal>
      </Modal>
    </div>
  )
}

export default Blog;