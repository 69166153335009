import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import downloadLinkService from "./downloadLinkService";

const initialState = {
  downloadLink: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};
// Download Link
export const updateDownloadLink = createAsyncThunk(
  "downloadLink/update",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await downloadLinkService.updateDownloadLink(data,  token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error.response.status === 401) {
        console.log(error.response.status);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getDownloadLink = createAsyncThunk("get/downloadLink", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await downloadLinkService.getDownloadLink(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const downloadLinkSlice = createSlice({
  name: "downloadLinkSlice",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateDownloadLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDownloadLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.downloadLink = action.payload;
      })
      .addCase(updateDownloadLink.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getDownloadLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDownloadLink.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.downloadLink = action.payload;
      })
      .addCase(getDownloadLink.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});
export const { reset } = downloadLinkSlice.actions;
export default downloadLinkSlice.reducer;
