import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  PageHeader,
  message,
  Upload,
  Space,
  Image,
  Row,
  Col,
  List,
  Divider,
  Form,
  Input,
  Modal,
  Spin,
  Card,
} from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { createSub1, getSub1, reset } from "../features/pricing/pricingSlice";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};


function Subscription1() {
  const dispatch = useDispatch();
  const { sub1, isLoading, uploadSuccess, isError } = useSelector(
    (state) => state.pricing
  );
  useEffect(() => {
    dispatch(reset());
    dispatch(getSub1());
  }, []);

  useEffect(() => {
    if (uploadSuccess) {
      message.success(`Saved successfully.`);
    }
    if (isError) {
      message.error(`Save error.`);
    }
  }, [uploadSuccess, isError]);

  const onFinish = async (values) => {
    console.log("Received values of form:", values);
    const formData = new FormData();
    for (let benefit of values.benefits) {
      console.log(benefit);
      await formData.append("benefits[]", benefit);
    }
    // await formData.append('image', imgList[0]);
    await formData.append("description", values.description);
    await formData.append("originalPrice", values.originalPrice);
    await formData.append("promotionPrice", values.promotionPrice);
    await formData.append("urlLink", values.urlLink);
    await formData.append("actionName", values.actionName);
    await dispatch(createSub1(formData));
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="Subscription 1"
        subTitle="This section is a sub1 in pricing page."
      />
      <Row>
        <Col md={12} sm={24} style={{ marginBottom: "20px" }}>
          {sub1.length > 0 ? (
            <Card title="Update Subscription 1">
              {/* <Divider orientation="left">Change Subscription 1</Divider> */}
              <Form
                name="dynamic_form_item"
                {...formItemLayoutWithOutLabel}
                onFinish={onFinish}
              >
                <Form.Item
                  label="Description"
                  name="description"
                  initialValue={sub1[0]&& sub1[0].description }
                  rules={[
                    {
                      required: true,
                      message: "Please input your description!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Original Price"
                  name="originalPrice"
                  initialValue={sub1[0]&& sub1[0].originalPrice }
                  rules={[
                    {
                      required: true,
                      message: "Please input your original price!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Url Link"
                  name="urlLink"
                  initialValue={sub1[0]&& sub1[0].urlLink}
                  rules={[
                    {
                      required: true,
                      message: "Please input your urlLink!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Action Name"
                  name="actionName"
                  initialValue={sub1[0] && sub1[0].actionName }
                  rules={[
                    {
                      required: true,
                      message: "Please input your button text!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.List
                  name="benefits"
                  initialValue={sub1[0] && sub1[0].benefits }
                  rules={[
                    {
                      validator: async (_, names) => {
                        if (!names || names.length < 2) {
                          return Promise.reject(new Error("At least 2 benefits"));
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          {...(index === 0
                            ? formItemLayout
                            : formItemLayoutWithOutLabel)}
                          label={index === 0 ? "Benefits" : ""}
                          required={false}
                          key={field.key}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message:
                                  "Please input benefit's name or delete this field.",
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              placeholder="benefit"
                              style={{
                                width: "90%",
                              }}
                            />
                          </Form.Item>
                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(field.name)}
                            />
                          ) : null}
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{
                            width: "90%",
                          }}
                          icon={<PlusOutlined />}
                        >
                          Add benefit
                        </Button>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add("The head item", 0);
                          }}
                          style={{
                            width: "90%",
                            marginTop: "20px",
                          }}
                          icon={<PlusOutlined />}
                        >
                          Add benefit at head
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                {/* <Divider orientation="left">UrlLink</Divider> */}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    // disabled={imgList.length === 0}
                    loading={isLoading}
                  >
                    {isLoading ? "Submitting" : "Submit"}
                  </Button>
                </Form.Item>
              </Form>
              {/* </Space> */}
            </Card>
          ) : (
            <div>Loading...</div>
            // <Card title="Update Subscription 1">
            //   {/* <Divider orientation="left">Change Subscription 1</Divider> */}
            //   <Form
            //     name="dynamic_form_item"
            //     {...formItemLayoutWithOutLabel}
            //     onFinish={onFinish}
            //   >
            //     <Form.Item
            //       label="Description"
            //       name="description"
            //       initialValue={sub1[0] ? sub1[0].description : ''}
            //       rules={[
            //         {
            //           required: true,
            //           message: "Please input your description!",
            //         },
            //       ]}
            //     >
            //       <Input />
            //     </Form.Item>
            //     <Form.Item
            //       label="Original Price"
            //       name="originalPrice"
            //       initialValue={sub1[0] ? sub1[0].originalPrice : ''}
            //       rules={[
            //         {
            //           required: true,
            //           message: "Please input your original price!",
            //         },
            //       ]}
            //     >
            //       <Input />
            //     </Form.Item>

            //     <Form.Item
            //       label="Url Link"
            //       name="urlLink"
            //       initialValue={sub1[0] ? sub1[0].urlLink : ''}
            //       rules={[
            //         {
            //           required: true,
            //           message: "Please input your urlLink!",
            //         },
            //       ]}
            //     >
            //       <Input />
            //     </Form.Item>
            //     <Form.Item
            //       label="Action Name"
            //       name="actionName"
            //       initialValue={sub1[0] ? sub1[0].actionName : ''}
            //       rules={[
            //         {
            //           required: true,
            //           message: "Please input your button text!",
            //         },
            //       ]}
            //     >
            //       <Input />
            //     </Form.Item>

            //     <Form.List
            //       name="benefits"
            //       initialValue={sub1[0] ? sub1[0].benefits : ''}
            //       rules={[
            //         {
            //           validator: async (_, names) => {
            //             if (!names || names.length < 2) {
            //               return Promise.reject(new Error("At least 2 benefits"));
            //             }
            //           },
            //         },
            //       ]}
            //     >
            //       {(fields, { add, remove }, { errors }) => (
            //         <>
            //           {fields.map((field, index) => (
            //             <Form.Item
            //               {...(index === 0
            //                 ? formItemLayout
            //                 : formItemLayoutWithOutLabel)}
            //               label={index === 0 ? "Benefits" : ""}
            //               required={false}
            //               key={field.key}
            //             >
            //               <Form.Item
            //                 {...field}
            //                 validateTrigger={["onChange", "onBlur"]}
            //                 rules={[
            //                   {
            //                     required: true,
            //                     whitespace: true,
            //                     message:
            //                       "Please input benefit's name or delete this field.",
            //                   },
            //                 ]}
            //                 noStyle
            //               >
            //                 <Input
            //                   placeholder="benefit"
            //                   style={{
            //                     width: "90%",
            //                   }}
            //                 />
            //               </Form.Item>
            //               {fields.length > 1 ? (
            //                 <MinusCircleOutlined
            //                   className="dynamic-delete-button"
            //                   onClick={() => remove(field.name)}
            //                 />
            //               ) : null}
            //             </Form.Item>
            //           ))}
            //           <Form.Item>
            //             <Button
            //               type="dashed"
            //               onClick={() => add()}
            //               style={{
            //                 width: "90%",
            //               }}
            //               icon={<PlusOutlined />}
            //             >
            //               Add benefit
            //             </Button>
            //             <Button
            //               type="dashed"
            //               onClick={() => {
            //                 add("The head item", 0);
            //               }}
            //               style={{
            //                 width: "90%",
            //                 marginTop: "20px",
            //               }}
            //               icon={<PlusOutlined />}
            //             >
            //               Add benefit at head
            //             </Button>
            //             <Form.ErrorList errors={errors} />
            //           </Form.Item>
            //         </>
            //       )}
            //     </Form.List>
            //     {/* <Divider orientation="left">UrlLink</Divider> */}
            //     <Form.Item>
            //       <Button
            //         type="primary"
            //         htmlType="submit"
            //         block
            //         // disabled={imgList.length === 0}
            //         loading={isLoading}
            //       >
            //         {isLoading ? "Submitting" : "Submit"}
            //       </Button>
            //     </Form.Item>
            //   </Form>
            //   {/* </Space> */}
            // </Card>
          )}
        </Col>
        <Col md={12} sm={24}>
          <Spin spinning={isLoading}>
            <Card title="Preview">
              {sub1.length > 0 ? (
                <div>
                  {sub1[0].description !== undefined ? (
                    <>
                      <Divider orientation="left">Description</Divider>
                      <List
                        bordered
                        dataSource={[sub1[0].description]}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                      />
                    </>
                  ) : null}
                  {sub1[0].originalPrice !== undefined ? (
                    <>
                      <Divider orientation="left">Original Price</Divider>
                      <List
                        bordered
                        dataSource={[sub1[0].originalPrice]}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                      />
                    </>
                  ) : null}
                  <Divider orientation="left">Benefits</Divider>
                  <List
                    bordered
                    dataSource={sub1[0].benefits}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />

                  {sub1[0].urlLink !== undefined ? (
                    <>
                      <Divider orientation="left">Url Link</Divider>
                      <List
                        bordered
                        dataSource={[
                          sub1[0].urlLink.length > 30
                            ? sub1[0].urlLink.substr(0, 40) + "..."
                            : sub1[0].urlLink,
                        ]}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                      />
                    </>
                  ) : null}
                  {sub1[0].actionName !== undefined ? (
                    <>
                      <Divider orientation="left">Action Name</Divider>
                      <List
                        bordered
                        dataSource={[sub1[0].actionName]}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                      />
                    </>
                  ) : null}
                </div>
              ) : (
                <div>Loading...</div>
              )}
            </Card>
          </Spin>
        </Col>
      </Row>
    </div>
  );
}

export default Subscription1;
