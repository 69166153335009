import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, PageHeader, message, Upload, Space, Image, Row, Col, List, Divider, Form, Input, Modal, Spin, Card } from 'antd';
import { InboxOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { createSub2, getSub2, reset } from "../features/pricing/pricingSlice";
const { Dragger } = Upload;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function Subscription2() {
  const dispatch = useDispatch();
  const [imgList, setImgList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const { sub2, isLoading, uploadSuccess, isError } = useSelector(
    (state) => state.pricing
  );
  useEffect(() => {
    dispatch(reset());
    dispatch(getSub2());
  }, []);

  useEffect(() => {
    if (uploadSuccess) {
      message.success(`Saved successfully.`);
    }
    if (isError) {
      message.error(`Save error.`);
    }
  }, [uploadSuccess, isError]);

  const props = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
    beforeUpload: (file) => {
      setImgList([file]);
      // console.log(imgList)
      return false;
    },
    onRemove: () => {
      setImgList([]);
      // console.log(imgList)
    },
    imgList,
    listType: "picture-card",
    async onPreview(file) {
      console.log(file)
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      // setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    },
  };

  const handleCancel = () => setPreviewOpen(false);
  // const handlePreview = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setPreviewImage(file.url || file.preview);
  //   setPreviewOpen(true);
  //   setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  // };

  const onFinish = async (values) => {
    console.log('Received values of form:', values);
    const formData = new FormData();
    for (let benefit of values.benefits) {
      console.log(benefit)
      await formData.append('benefits[]', benefit);
    }
    // await formData.append('image', imgList[0]);
    await formData.append('description', values.description);
    await formData.append('originalPrice', values.originalPrice);
    await formData.append('promotionPrice', values.promotionPrice);
    await formData.append('urlLink', values.urlLink);
    await formData.append('actionName', values.actionName);
    await dispatch(createSub2(formData));
    setImgList([]);
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="Subscription 2"
        subTitle="This section is a sub2 in pricing page."
      />
      <Row>
        <Col md={12} sm={24} style={{ marginBottom: '20px' }}>
        {sub2.length > 0 ? (
          <Card title="Update Subscription 2">
            {/* <Divider orientation="left">Change Subscription 2</Divider> */}
            <Form name="dynamic_form_item" {...formItemLayoutWithOutLabel} onFinish={onFinish}>
              <Form.Item
                label="Description"
                name="description"
                initialValue={sub2[0] && sub2[0].description}
                rules={[
                  {
                    required: true,
                    message: 'Please input your description!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Original Price"
                name="originalPrice"
                initialValue={sub2[0] && sub2[0].originalPrice}
                rules={[
                  {
                    required: true,
                    message: 'Please input your original price!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Promotion Price"
                name="promotionPrice"
                initialValue={sub2[0] && sub2[0].promotionPrice}
                rules={[
                  {
                    required: true,
                    message: 'Please input your promotion price!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Url Link"
                name="urlLink"
                initialValue={sub2[0] && sub2[0].urlLink}
                rules={[
                  {
                    required: true,
                    message: 'Please input your urlLink!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Action Name"
                name="actionName"
                initialValue={sub2[0] && sub2[0].actionName}
                rules={[
                  {
                    required: true,
                    message: 'Please input your button text!',
                  },
                ]}
              >
                <Input />
              </Form.Item>


              {/* <Space style={{ display: 'block' }}>

              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                  Support for a single upload. Strictly prohibit from uploading company data or other
                  band files
                </p>
              </Dragger>
              <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                  alt="example"
                  style={{
                    width: '100%',
                  }}
                  src={previewImage}
                />
              </Modal>
            </Space> 
            <Divider orientation="left">Benefits</Divider> */}
              {/* <Space style={{ display: 'block' }}> */}

              <Form.List
                name="benefits"
                initialValue={sub2[0] && sub2[0].benefits}
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length < 2) {
                        return Promise.reject(new Error('At least 2 benefits'));
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                        label={index === 0 ? 'Benefits' : ''}
                        required={false}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message: "Please input benefit's name or delete this field.",
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder="benefit"
                            style={{
                              width: '90%',
                            }}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{
                          width: '90%',
                        }}
                        icon={<PlusOutlined />}
                      >
                        Add benefit
                      </Button>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add('The head item', 0);
                        }}
                        style={{
                          width: '90%',
                          marginTop: '20px',
                        }}
                        icon={<PlusOutlined />}
                      >
                        Add benefit at head
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
              {/* <Divider orientation="left">UrlLink</Divider> */}
              <Form.Item>
                <Button type="primary" htmlType="submit" block
                  // disabled={imgList.length === 0}
                  loading={isLoading}>
                  {isLoading ? 'Submitting' : 'Submit'}
                </Button>
              </Form.Item>
            </Form>
            {/* </Space> */}
          </Card>
          ) : (
            <div>Loading...</div>
          )}
        </Col>
        <Col md={12} sm={24}>
          <Spin spinning={isLoading}>
              <Card title="Preview">
                {sub2.length > 0 ?
                  <div>
                    {/* <Image
                      width={"95%"}
                      src={sub2[0].url}
                      preview={{
                        src: sub2[0].url,
                      }}
                      style={{ boxShadow: 'gray 1px 1px 2px 1px' }}
                    /> */}
                    {/* <br /><br /> */}
                    {sub2[0].description !== undefined ?
                      <>
                        <Divider orientation="left">Description</Divider>
                        <List
                          bordered
                          dataSource={[sub2[0].description]}
                          renderItem={(item) => (
                            <List.Item>
                              {item}
                            </List.Item>
                          )}
                        />
                      </> : null
                    }
                    {sub2[0].originalPrice !== undefined ?
                      <>
                        <Divider orientation="left">Original Price</Divider>
                        <List
                          bordered
                          dataSource={[sub2[0].originalPrice]}
                          renderItem={(item) => (
                            <List.Item>
                              {item}
                            </List.Item>
                          )}
                        />
                      </> : null
                    }
                    {sub2[0].promotionPrice !== undefined ?
                      <>
                        <Divider orientation="left">Promotion Price</Divider>
                        <List
                          bordered
                          dataSource={[sub2[0].promotionPrice]}
                          renderItem={(item) => (
                            <List.Item>
                              {item}
                            </List.Item>
                          )}
                        />
                      </> : null
                    }
                    <Divider orientation="left">Benefits</Divider>
                    <List
                      bordered
                      dataSource={sub2[0].benefits}
                      renderItem={(item) => (
                        <List.Item>
                          {item}
                        </List.Item>
                      )}
                    />

                    {sub2[0].urlLink !== undefined ?
                      <>
                        <Divider orientation="left">Url Link</Divider>
                        <List
                          bordered
                          dataSource={[sub2[0].urlLink.length > 30 ? sub2[0].urlLink.substr(0, 40) + "..." : sub2[0].urlLink]}
                          renderItem={(item) => (
                            <List.Item>
                              {item}
                            </List.Item>
                          )}
                        />
                      </> : null
                    }
                    {sub2[0].actionName !== undefined ?
                      <>
                        <Divider orientation="left">Action Name</Divider>
                        <List
                          bordered
                          dataSource={[sub2[0].actionName]}
                          renderItem={(item) => (
                            <List.Item>
                              {item}
                            </List.Item>
                          )}
                        />
                      </> : null
                    }
                  </div> :
                  <div>Loading...</div>}
              </Card>
          </Spin>
        </Col>
      </Row>
    </div>
  )
}

export default Subscription2;