import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  PageHeader,
  message,
  Space,
  Form,
  List,
  Divider,
  Row,
  Col,
  Select,
  Spin,
  DatePicker,
  Input,
  Card,
} from "antd";
import {
  InboxOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  updatePromotion,
  getPromotion,
  reset,
} from "../features/promotion/promotionSlice";

const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};


function Promotion() {
  const dispatch = useDispatch();
  const [promoPackage, setPromoPackage] = useState("001");
  const { promotion, isLoading, isSuccess, isError } = useSelector(
    (state) => state.promotion
  );
  useEffect(() => {
    dispatch(reset());
    dispatch(getPromotion());
  }, []);

  useEffect(() => {
    if (isSuccess) {
      message.success(`Updated successfully.`);
    }
    if (isError) {
      message.error(`Update error.`);
    }
  }, [isSuccess, isError]);

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setPromoPackage(value);
    // console.log(promoPackage)
  };

  const onFinish = async (values) => {
    console.log("Success:", values);
    console.log("package:", promoPackage);
    let benefits = [];
    for (let benefit of values.benefits) {
      console.log(benefit);
      await benefits.push(benefit);
      // await formData.append("benefits[]", benefit);
    }
    await dispatch(
      updatePromotion({
        description: values.description,
        originalPrice: values.originalPrice,
        urlLink: values.urlLink,
        benefits: benefits,
        actionName: values.actionName,
        endDate: values.endDate,
        promoDescription: values.promoDescription,
      })
    );
    dispatch(getPromotion());
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="Promotion"
        subTitle="This section is to set promotion page."
      />
      <Row>
        <Col md={12} sm={24} style={{ marginBottom: "20px" }}>
        {promotion != null ? (
          <Card title="Update Promotion Page">
          <Form
              name="dynamic_form_item"
              {...formItemLayoutWithOutLabel}
              onFinish={onFinish}
            >
              <Form.Item
                label="Description"
                name="description"
                initialValue={promotion.description}
                rules={[
                  {
                    required: true,
                    message: "Please input your description!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Original Price"
                name="originalPrice"
                initialValue={promotion.originalPrice}
                rules={[
                  {
                    required: true,
                    message: "Please input your original price!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              {/* <Form.Item
                label="Promotion Price"
                name="promotionPrice"
                rules={[
                  {
                    required: true,
                    message: "Please input your promotion price!",
                  },
                ]}
              >
                <Input />
              </Form.Item> */}

              <Form.Item
                label="Url Link"
                name="urlLink"
                initialValue={promotion.urlLink}
                rules={[
                  {
                    required: true,
                    message: "Please input your urlLink!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Action Name"
                name="actionName"
                initialValue={promotion.actionName}
                rules={[
                  {
                    required: true,
                    message: "Please input your button text!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.List
                name="benefits"
                initialValue={promotion.benefits}
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length < 2) {
                        return Promise.reject(new Error("At least 2 benefits"));
                      }
                    },
                  },
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...(index === 0
                          ? formItemLayout
                          : formItemLayoutWithOutLabel)}
                        label={index === 0 ? "Benefits" : ""}
                        required={false}
                        key={field.key}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={["onChange", "onBlur"]}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                "Please input benefit's name or delete this field.",
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder="benefit"
                            style={{
                              width: "90%",
                            }}
                          />
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{
                          width: "90%",
                        }}
                        icon={<PlusOutlined />}
                      >
                        Add benefit
                      </Button>
                      <Button
                        type="dashed"
                        onClick={() => {
                          add("The head item", 0);
                        }}
                        style={{
                          width: "90%",
                          marginTop: "20px",
                        }}
                        icon={<PlusOutlined />}
                      >
                        Add benefit at head
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>
              {/* <Divider orientation="left">UrlLink</Divider> */}
              <Form.Item name="endDate" label="End Date" 
              rules={[
                {
                  required: true,
                  message: "Please select promotion end date!",
                },
              ]}>
                <DatePicker />
              </Form.Item>
              <Form.Item name="promoDescription" label="Promo Desription" initialValue={promotion.promoDescription}
              rules={[
                {
                  required: true,
                  message: "Please input your promotion description text!",
                },
              ]}>
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  // disabled={imgList.length === 0}
                  loading={isLoading}
                >
                  {isLoading ? "Submitting" : "Submit"}
                </Button>
              </Form.Item>
            </Form>
          </Card>
          ) : (
            <div>Loading...</div>
          )}
        </Col>
        <Col md={12} sm={24}>
        <Spin spinning={isLoading}>
              <Card title="Preview">
                {promotion ? (
                  <div>
                    {promotion.description !== undefined ? (
                      <>
                        <Divider orientation="left">Description</Divider>
                        <List
                          bordered
                          dataSource={[promotion.description]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </>
                    ) : null}
                    {promotion.originalPrice !== undefined ? (
                      <>
                        <Divider orientation="left">Original Price</Divider>
                        <List
                          bordered
                          dataSource={[promotion.originalPrice]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </>
                    ) : null}
                    <Divider orientation="left">Benefits</Divider>
                    <List
                      bordered
                      dataSource={promotion.benefits}
                      renderItem={(item) => <List.Item>{item}</List.Item>}
                    />

                    {promotion.urlLink !== undefined ? (
                      <>
                        <Divider orientation="left">Url Link</Divider>
                        <List
                          bordered
                          dataSource={[
                            promotion.urlLink.length > 30
                              ? promotion.urlLink.substr(0, 40) + "..."
                              : promotion.urlLink,
                          ]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </>
                    ) : null}
                    {promotion.actionName !== undefined ? (
                      <>
                        <Divider orientation="left">Action Name</Divider>
                        <List
                          bordered
                          dataSource={[promotion.actionName]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </>
                    ) : null}
                    {/* {promotion.packageDoc &&
                    promotion.packageDoc.description !== undefined ? (
                      <>
                        <Divider orientation="left">Promotion Package</Divider>
                        <List
                          bordered
                          dataSource={[promotion.packageDoc.description]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </>
                    ) : null} */}
                    {promotion &&
                    promotion.endDate !== undefined ? (
                      <>
                        <Divider orientation="left">End Date</Divider>
                        <List
                          bordered
                          dataSource={[promotion.endDate]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </>
                    ) : null}
                    {promotion &&
                    promotion.promoDescription !== undefined ? (
                      <>
                        <Divider orientation="left">Promotion Description</Divider>
                        <List
                          bordered
                          dataSource={[promotion.promoDescription]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </>
                    ) : null}
                  </div>
                ) : (
                  <div>Loading...</div>
                )}
              </Card>
          </Spin>
          {/* <Spin spinning={isLoading}>
            <Space width={"99%"}>
              <Card>
                {promotion ? (
                  <div>
                    {promotion.packageDoc &&
                    promotion.packageDoc.description !== undefined ? (
                      <>
                        <Divider orientation="left">Promotion Package</Divider>
                        <List
                          bordered
                          dataSource={[promotion.packageDoc.description]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </>
                    ) : null}
                    {promotion.promotionDoc &&
                    promotion.promotionDoc.endDate !== undefined ? (
                      <>
                        <Divider orientation="left">End Date</Divider>
                        <List
                          bordered
                          dataSource={[promotion.promotionDoc.endDate]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </>
                    ) : null}
                    {promotion.promotionDoc &&
                    promotion.promotionDoc.description !== undefined ? (
                      <>
                        <Divider orientation="left">Description</Divider>
                        <List
                          bordered
                          dataSource={[promotion.promotionDoc.description]}
                          renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                      </>
                    ) : null}
                  </div>
                ) : (
                  <div>Loading...</div>
                )}
              </Card>
            </Space>
          </Spin> */}
        </Col>
      </Row>
    </div>
  );
}

export default Promotion;
