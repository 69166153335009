import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, PageHeader, message, Upload, Space, Image, Row, Col, Modal, Divider, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { createWhySN, getWhySN, reset } from "../features/homeFeatures/whySNSlice";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function WhySN() {
  const dispatch = useDispatch();
  const [imgList, setImgList] = useState([]);
  const [test, setTest] = useState([1, 2, 3, 4]);
  const { whySN, isLoading, uploadSuccess, isError } = useSelector(
    (state) => state.whySN
  );

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    dispatch(reset());
    dispatch(getWhySN());
    // console.log(WhySN)
  }, []);

  useEffect(() => {
    if (uploadSuccess) {
      message.success(`Image uploaded successfully.`);
    }
    if (isError) {
      message.error(`Image upload error.`);
    }
  }, [uploadSuccess, isError]);

  // const props = {
  //   name: 'file',
  //   multiple: false,
  //   maxCount: 1,
  //   onDrop(e) {
  //     console.log('Dropped files', e.dataTransfer.files);
  //   },
  //   beforeUpload: (file) => {
  //     setImgList([...imgList, file]);
  //     // setImgList([file]);
  //     console.log(imgList)
  //     return false;
  //   },
  //   onRemove: () => {
  //     const index = imgList.indexOf(file);
  //     const newFileList = imgList.slice();
  //     newFileList.splice(index, 1);
  //     setImgList(newFileList);
  //     // setImgList([]);
  //     console.log(imgList)
  //   },
  //   imgList,
  //   listType: "picture-card",
  //   async onPreview(file) {
  //     let src = file.thumbUrl;
  //     console.log(file)
  //     if (!src) {
  //       src = await new Promise((resolve) => {
  //         const reader = new FileReader();
  //         reader.readAsDataURL(file.originFileObj);
  //         reader.onload = () => resolve(reader.result);
  //       });
  //     }
  //     const image = new Image();
  //     image.src = src;
  //     const imgWindow = window.open(src);
  //     imgWindow?.document.write(image.outerHTML);
  //   }

  // };

  const beforeUpload = (file) => {
    setImgList([...imgList, file]);
    console.log(imgList)
    return false;
  };

  const onRemove = (file) => {
    const index = imgList.indexOf(file);
    const newFileList = imgList.slice();
    newFileList.splice(index, 1);
    setImgList(newFileList);
    console.log(imgList)
  };

  const handleUpload = () => {
    const formData = new FormData();
    imgList.forEach((file) => {
      formData.append('images', file);
    });
    dispatch(createWhySN(formData));
    setImgList([]);

  };

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="Why Shwe Note"
        subTitle="This section is a home page why shwenote."
      />
      <Row>
        <Col span={24} style={{ overflow: 'auto' }}>
          <Spin spinning={isLoading}>
            <Space>
              <Image.PreviewGroup>
                {whySN.length > 0 ? (
                  whySN.map((d) =>
                    <Image
                      key={d.img}
                      width={"150px"}
                      height={"200px"}
                      src={d.img}
                      preview={{
                        src: d.img,
                      }}
                      style={{ boxShadow: 'gray 1px 1px 2px 1px' }}
                    />
                  )) :
                  <Image
                    width={"95%"}
                    height={"200px"}
                    src="/image.jpg"
                  />}
              </Image.PreviewGroup>
            </Space>
          </Spin>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Space style={{ display: 'block' }}>
            <Upload
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
              beforeUpload={beforeUpload}
              onRemove={onRemove}
            >
              {fileList.length >= 6 ? null : uploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
              <img
                alt="example"
                style={{
                  width: '100%',
                }}
                src={previewImage}
              />
            </Modal>
          </Space>
          <Space style={{ display: 'block' }}>
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={imgList.length < 6}
              loading={isLoading}
              style={{ marginTop: 16 }}
              block
            >
              {isLoading ? 'Setting' : 'Set Image'}
            </Button>
          </Space>
        </Col>

      </Row>
    </div>
  )
}

export default WhySN;