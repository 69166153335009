import axios from "axios";
import { constants } from "../../app/constants";

const API_URL = constants.REACT_APP_API_BASE_URL + "/what-sn/post";
const API_URL_GET = constants.REACT_APP_API_BASE_URL + "/what-sn/all";
const createWhatSN = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL, data, config);

  return response.data;
};
const getWhatSN = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL_GET, config);

  return response.data;
};

const whatSNService = { createWhatSN, getWhatSN };
export default whatSNService;
