import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, PageHeader, message, Upload, Space, Image, Row, Col, Modal, Divider, Table, Form, Input, Radio, Popconfirm, Spin } from 'antd';
import { PlusOutlined, DeleteOutlined, EditOutlined, FileAddOutlined } from '@ant-design/icons';
import { createPopularBook, getPopularBooks, deletePopularBook, reset, updatePopularBook } from "../features/homeFeatures/popularBooksSlice";

const { TextArea } = Input;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function PopularBooks() {
  const dispatch = useDispatch();
  const [isUpdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [value, setValue] = useState("video");
  const [updateBookId, setUpdateBookId] = useState("");
  const [imgList, setImgList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const { popularBooks, isLoading, isSuccess, uploadSuccess, isError } = useSelector(
    (state) => state.popularBooks
  );

  const columns = [
    {
      title: 'url',
      dataIndex: 'url',
      key: 'url',
      render: (text) => <span>{text.length > 20 ? text.slice(0, 20) + "..." : text}</span>,
      responsive: ['md'],
    },
    {
      title: 'img',
      dataIndex: 'img',
      key: 'img',
      render: (text) => <span>{text.length > 20 ? text.slice(0, 20) + "..." : text}</span>,
      responsive: ['md'],
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="large">
          <EditOutlined
            style={{ color: "green" }}
            onClick={() => handleEdit(record)}
          />
          <Popconfirm
            title="Are you sure to delete this book?"
            onConfirm={() => handleDelete(record)}
            onCancel={cancelDelete}
            okText="Yes"
            cancelText="No"
            placement="left"
          >
            <DeleteOutlined
              style={{ color: "red" }}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const props = {
    name: 'file',
    multiple: false,
    maxCount: 1,
    beforeUpload: (file) => {
      setImgList([file]);
      // console.log(imgList)
      return false;
    },
    onRemove: () => {
      setImgList([]);
      // console.log(imgList)
    },
    imgList,
    listType: "picture-card",
    async onPreview(file) {
      console.log(file)
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
    },
  };

  useEffect(() => {
    dispatch(reset());
    dispatch(getPopularBooks());
  }, []);

  useEffect(() => {
    if (uploadSuccess) {
      message.success(`Book created successfully.`);
    }
    if (isError) {
      message.error(`Operation failed.`);
    }
    if (isSuccess) {
      message.success(`Operation success.`);
    }
  }, [isSuccess, isError]);

  const handleEdit = (book) => {
    console.log(book)
    setIsUpdate(true);
    setUpdateBookId(book._id);
    form.setFieldsValue({
      url: book.url,
    });
    // setValue(book.isVideo ? "video" : "image");
    setImgList([
      {
        uid: '-1',
        name: book.name,
        status: 'done',
        url: book.img,
      },
    ]);
    setOpen(true);
  };

  const handleDelete = (book) => {
    dispatch(deletePopularBook(book._id));
  }

  const onCreate = (values) => {
    console.log('Received values of form: ', values);
    setOpen(false);
    // if (values.modifier === "video") {
    //   let bookDocNew = {};
    //   bookDocNew.title = values.title;
    //   bookDocNew.description = values.description;
    //   bookDocNew.duration = values.duration;
    //   bookDocNew.url = values.url;
    //   bookDocNew.isVideo = true;
    //   if (isUpdate === true) {
    //     bookDocNew._id = updateBookId;
    //     dispatch(updatePopularBook(bookDocNew));
    //   } else {
    //     dispatch(createPopularBook(bookDocNew));
    //   }
    // } else {
      const formData = new FormData();
      formData.append('image', values.image.file);
      formData.append('url', values.url);
      dispatch(createPopularBook(formData));
    // }
  };

  const handleCancel = () => {
    setOpen(false);
    setIsUpdate(false);
    form.setFieldsValue({
      url: "",
    });
  }
  
  const handleCancelPreview = () => setPreviewOpen(false);
  const cancelDelete = (e) => {
    console.log(e);
    message.error('Delete cancel');
  };

  return (
    <div>

      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title="Popular Books"
        subTitle="This section is a popular books page."
        extra={[<Button type='primary' onClick={() => {
          setOpen(true);
        }}>Add New</Button>]}
      />
      <Spin spinning={isLoading}>
        <Table columns={columns} dataSource={popularBooks} rowKey={record => record._id} />
      </Spin>
      <Modal
        open={open}
        title={isUpdate ? "Update book" : "Create a new book"}
        okText="Save"
        cancelText="Cancel"
        onCancel={handleCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              onCreate(values);
            })
            .catch((info) => {
              console.log('Validate Failed:', info);
            });
        }}
        width={1000}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
        >
          <Form.Item
            name="url"
            label="URL"
            rules={[
              {
                required: true,
                message: 'Please input the title of url!',
              },
            ]}
          >
            <Input />
          </Form.Item>
            <Form.Item name="image" label="Image" className="collection-create-form_last-form-item"
              rules={[
                {
                  required: true,
                  message: 'Please upload the photo of book!',
                },
              ]}>
              <Upload {...props}>
                <div>
                  <PlusOutlined />
                  <div
                    style={{
                      marginTop: 8,
                    }}
                  >
                    Upload
                  </div>
                </div>
              </Upload>
            </Form.Item>
        </Form>
        <Modal open={previewOpen} footer={null} onCancel={handleCancelPreview}>
          <img
            alt="example"
            style={{
              width: '100%',
            }}
            src={previewImage}
          />
        </Modal>
      </Modal>
    </div>
  )
}

export default PopularBooks;