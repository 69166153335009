import * as React from 'react';
import { Button } from 'antd';

function Home() {
  return (
    <div>
      <Button type="primary">Home</Button>
    </div>
  )
}

export default Home;