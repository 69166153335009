import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import pricingService from "./pricingService";

const initialState = {
  sub1: [],
  sub2: [],
  sub3: [],
  sub4: [],
  howToPay: [],
  howToCoupon: [],
  howToRead: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  uploadSuccess: false
};
// Subscription 1
export const createSub1 = createAsyncThunk(
  "create/sub1",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await pricingService.createSub1(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error.response.status === 401) {
        console.log(error.response.status);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSub1 = createAsyncThunk("get/sub1", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await pricingService.getSub1(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Subscription 2
export const createSub2 = createAsyncThunk(
  "create/sub2",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await pricingService.createSub2(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error.response.status === 401) {
        console.log(error.response.status);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSub2 = createAsyncThunk("get/sub2", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await pricingService.getSub2(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Subscription 3
export const createSub3 = createAsyncThunk(
  "create/sub3",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await pricingService.createSub3(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error.response.status === 401) {
        console.log(error.response.status);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSub3 = createAsyncThunk("get/sub3", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await pricingService.getSub3(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Subscription 4
export const createSub4 = createAsyncThunk(
  "create/sub4",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await pricingService.createSub4(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error.response.status === 401) {
        console.log(error.response.status);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSub4 = createAsyncThunk("get/sub4", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await pricingService.getSub4(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// how to pay 
export const createHowToPay = createAsyncThunk(
  "create/howtopay",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await pricingService.createHowToPay(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error.response.status === 401) {
        console.log(error.response.status);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getHowToPay = createAsyncThunk("get/howtopay", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await pricingService.getHowToPay(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// how to add coupon 
export const createHowToCoupon = createAsyncThunk(
  "create/howtocoupon",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await pricingService.createHowToCoupon(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error.response.status === 401) {
        console.log(error.response.status);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getHowToCoupon = createAsyncThunk("get/howtocoupon", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await pricingService.getHowToCoupon(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// how to add read 
export const createHowToRead = createAsyncThunk(
  "create/howtoread",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await pricingService.createHowToRead(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error.response.status === 401) {
        console.log(error.response.status);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getHowToRead = createAsyncThunk("get/howtoread", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await pricingService.getHowToRead(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const pricingSlice = createSlice({
  name: "pricingSlice",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
    // Subscription 1
      .addCase(createSub1.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSub1.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.uploadSuccess = true;
        state.sub1 = [action.payload];
      })
      .addCase(createSub1.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSub1.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSub1.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sub1 = action.payload;
      })
      .addCase(getSub1.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Subscription 2
      .addCase(createSub2.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSub2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.uploadSuccess = true;
        state.sub2 = [action.payload];
      })
      .addCase(createSub2.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSub2.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSub2.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sub2 = action.payload;
      })
      .addCase(getSub2.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Subscription 3
      .addCase(createSub3.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSub3.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.uploadSuccess = true;
        state.sub3 = [action.payload];
      })
      .addCase(createSub3.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSub3.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSub3.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sub3 = action.payload;
      })
      .addCase(getSub3.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Subscription 4
      .addCase(createSub4.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSub4.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.uploadSuccess = true;
        state.sub4 = [action.payload];
      })
      .addCase(createSub4.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSub4.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSub4.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sub4 = action.payload;
      })
      .addCase(getSub4.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // How to pay
      .addCase(createHowToPay.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createHowToPay.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.uploadSuccess = true;
        state.howToPay = action.payload;
      })
      .addCase(createHowToPay.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getHowToPay.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHowToPay.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.howToPay = action.payload;
      })
      .addCase(getHowToPay.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // How to coupon
      .addCase(createHowToCoupon.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createHowToCoupon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.uploadSuccess = true;
        state.howToCoupon = action.payload;
      })
      .addCase(createHowToCoupon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getHowToCoupon.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHowToCoupon.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.howToCoupon = action.payload;
      })
      .addCase(getHowToCoupon.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createHowToRead.pending, (state) => {
        state.isLoading = true;
      })
      // How to read
      .addCase(createHowToRead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.uploadSuccess = true;
        state.howToRead = action.payload;
      })
      .addCase(createHowToRead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getHowToRead.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getHowToRead.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.howToRead = action.payload;
      })
      .addCase(getHowToRead.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});
export const { reset } = pricingSlice.actions;
export default pricingSlice.reducer;
