import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../features/auth/authSlice";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  AppstoreOutlined,
  HomeOutlined,
  DollarCircleOutlined,
  ReadOutlined,
  SettingOutlined,
  InfoCircleOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import { Layout, Menu } from "antd";

const { Header, Sider, Content, Footer } = Layout;

const rootSubmenuKeys = ['2', '3', '4', '5', '6'];

const items = [
  // {
  //   key: "1",
  //   icon: <AppstoreOutlined />,
  //   label: "Dashboard",
  // },
  {
    key: "2",
    icon: <HomeOutlined />,
    label: "Home",
    children: [
      {
        key: "home1",
        icon: <HomeOutlined />,
        label: "Hero Super"
      },
      {
        key: "home2",
        icon: <HomeOutlined />,
        label: "Features"
      },
      {
        key: "home3",
        icon: <HomeOutlined />,
        label: "What"
      },
      {
        key: "home4",
        icon: <HomeOutlined />,
        label: "Why"
      },
      {
        key: "home5",
        icon: <HomeOutlined />,
        label: "Popular Books"
      },
      {
        key: "home6",
        icon: <HomeOutlined />,
        label: "Feedbacks"
      }
    ]
  },
  {
    key: "3",
    icon: <DollarCircleOutlined />,
    label: "Pricing",
    children: [
      {
        key: "price1",
        icon: <DollarCircleOutlined />,
        label: "Subscription 1"
      },
      {
        key: "price2",
        icon: <DollarCircleOutlined />,
        label: "Subscription 2"
      },
      {
        key: "price3",
        icon: <DollarCircleOutlined />,
        label: "Subscription 3"
      },
      // {
      //   key: "price4",
      //   icon: <DollarCircleOutlined />,
      //   label: "Subscription 4"
      // },
      {
        key: "price4",
        icon: <DollarCircleOutlined />,
        label: "How To Pay"
      },
      {
        key: "price5",
        icon: <DollarCircleOutlined />,
        label: "How To Coupon"
      },
      {
        key: "price6",
        icon: <DollarCircleOutlined />,
        label: "How To Read"
      }
    ]
  },
  {
    key: "4",
    icon: <ReadOutlined />,
    label: "Blog",
  },
  {
    key: "5",
    icon: <InfoCircleOutlined />,
    label: "Promotion",
  },
  {
    key: "6",
    icon: <DownloadOutlined />,
    label: "Download Link",
  },
  {
    key: "7",
    icon: <SettingOutlined />,
    label: "Settings",
  },
];

const LayoutCon = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState('2');
  const { user, isError, message, isSuccess } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    console.log(user);
    if (!user) {
      navigate("login");
    }
    dispatch(reset());
  }, [user, navigate, isError, message, dispatch, isSuccess]);

  const [openKeys, setOpenKeys] = useState(['2']);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
    switch (e.key) {
      case '1':
        navigate('');
        break;
      case '2':
        navigate('home');
        break;
      case '3':
        navigate('pricing');
        break;
      case '4':
        navigate('blog');
        break;
      case '5':
        navigate('promotion');
        break;
      case '6':
        navigate('downloadLink');
        break;
      case '7':
        navigate('setting');
        break;

      case 'home1':
        navigate('hero-super');
        break;
      case 'home2':
        navigate('features')
        break;
      case 'home3':
        navigate('what-sn');
        break;
      case 'home4':
        navigate('why-sn');
        break;
      case 'home5':
        navigate('popular-books');
        break;
      case 'home6':
        navigate('feedbacks');
        break;
      case 'price1':
        navigate('sub1');
        break;
      case 'price2':
        navigate('sub2');
        break;
      case 'price3':
        navigate('sub3');
        break;
      // case 'price4':
      //   navigate('sub4');
        break;
      case 'price4':
        navigate('howtopay');
        break;
      case 'price5':
        navigate('howtocoupon');
        break;
      case 'price6':
        navigate('howtoread');
        break;
      default:
        navigate('/')
    }
  };
  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className="layout-sider"
      >
        <div className="logo"></div>
        <Menu
          theme="dark"
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          defaultSelectedKeys={["1"]}
          onClick={onClick}
          selectedKeys={[current]}
          items={items}
          style={{ marginBottom: '20px' }}
        />
      </Sider>
      <Layout className="site-layout"
      >
        <Header className="site-layout-background">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
          <span style={{ color: 'white', fontSize: 18, fontWeight: 'bold' }}>Shwe Note Web CMS</span>
        </Header>
        <Content
          className="site-layout-content"
          style={{
            margin: "85px 16px 50px 16px",
            padding: 24,
            // minHeight: 'auto',
            marginLeft: collapsed ? 95 : 215,
          }}
        >
          <Outlet />
        </Content>
      </Layout>
      <Footer>
        Shwe Note App ©2022 Created by Naing Ye Oo.
      </Footer>
    </Layout>
  );
};
export default LayoutCon;
