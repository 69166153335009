import * as React from 'react';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reset, login } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Avatar, Typography, notification, Card } from 'antd';

const { Title } = Typography;

const errorNoti = () => {
  const args = {
    message: 'Error!',
    description:
      'Username or password is incorrect.',
    duration: 4,
  };
  notification.error(args);
};

const successNoti = () => {
  const args = {
    message: 'Success!',
    description:
      'You have successfully logged in.',
    duration: 5,
  };
  notification.success(args);
};


const LoginAnt = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  );
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (isError) {
      errorNoti();
    }
    if (isSuccess || user) {
      successNoti();
      navigate("/");
    }
    dispatch(reset());
  }, [[user, isError, isSuccess, message, navigate, dispatch]]);

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setFormData((preState) => ({ ...preState, [name]: value }));
  };

  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    dispatch(login(formData));
  };

  return (
    <div className='login-container'>
      <Card size='small' className='login-card'>
        <Form
          name="normal_login"
          className="login-form"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Avatar size={64} icon={<UserOutlined />} />
          <Title style={{ color: 'rgb(1, 173, 181)' }} level={4}>Sign In</Title>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your Username!',
              },
            ]}
          >
            <Input name="email" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" onChange={HandleChange} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your Password!',
              },
            ]}
          >
            <Input
              name="password"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="Password"
              onChange={HandleChange}
            />
          </Form.Item>

          <Form.Item>
            <Button loading={isLoading} type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button>
          </Form.Item>
          {'Copyright © '}
          <a color="inherit" href="https://shwenoteapp.com/">
            Shwe Note App
          </a>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Form>
      </Card>
    </div>
  );
}

export default LoginAnt;