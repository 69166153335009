import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import popularBooksService from "./popularBooksService";

const initialState = {
  popularBooks: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  uploadSuccess: false
};
// Book
export const createPopularBook = createAsyncThunk(
  "create/book",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await popularBooksService.createPopularBook(data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error.response.status === 401) {
        console.log(error.response.status);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getPopularBooks = createAsyncThunk("get/book", async (_, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await popularBooksService.getPopularBooks(token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updatePopularBook = createAsyncThunk(
  "update/book",
  async (data, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token;
      return await popularBooksService.updatePopularBook(data._id, data, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      if (error.response.status === 401) {
        console.log(error.response.status);
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deletePopularBook = createAsyncThunk("delete/book", async (bookId, thunkAPI) => {
  try {
    const token = thunkAPI.getState().auth.user.token;
    return await popularBooksService.deletePopularBook(bookId, token);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const popularBooksSlice = createSlice({
  name: "popularBooksSlice",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPopularBook.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPopularBook.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.uploadSuccess = true;
        state.popularBooks.unshift(action.payload);
      })
      .addCase(createPopularBook.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getPopularBooks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPopularBooks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.popularBooks = action.payload;
      })
      .addCase(getPopularBooks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updatePopularBook.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePopularBook.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.uploadSuccess = true;
        let ind = state.popularBooks.findIndex(b => b._id === action.payload._id);
        if(ind !== -1){
          state.popularBooks[ind] = action.payload;
        }
        // state.book.unshift(action.payload);
      })
      .addCase(updatePopularBook.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deletePopularBook.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deletePopularBook.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.popularBooks = state.popularBooks.filter(b => b._id !== action.payload.id);
      })
      .addCase(deletePopularBook.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});
export const { reset } = popularBooksSlice.actions;
export default popularBooksSlice.reducer;
