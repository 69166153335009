import axios from "axios";
import { constants } from "../../app/constants";

const API_URL = constants.REACT_APP_API_BASE_URL;
// const API_URL_GET = constants.REACT_APP_API_BASE_URL + "/what-sn/all";

const createBlog = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "/blog", data, config);

  return response.data;
};
const getBlog = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "/blog", config);

  return response.data;
};
const getBlogById = async (blogId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "/blog/" + blogId, config);
  return response.data;
};
const updateBlog = async (blogId, data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.put(API_URL + "/blog/" + blogId, data, config);
  return response.data;
};
const deleteBlog = async (blogId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.delete(API_URL + "/blog/" + blogId, config);
  return response.data;
};

const blogService = { createBlog, getBlog, getBlogById, updateBlog, deleteBlog};
export default blogService;
