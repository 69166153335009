import axios from "axios";
import { constants } from "../../app/constants";

const API_URL = constants.REACT_APP_API_BASE_URL;
// const API_URL_GET = constants.REACT_APP_API_BASE_URL + "/what-sn/all";

// subscription 1
const createSub1 = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "/pricing/sub1/post", data, config);

  return response.data;
};
const getSub1 = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "/pricing/sub1/all", config);

  return response.data;
};

// subscription 2
const createSub2 = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "/pricing/sub2/post", data, config);

  return response.data;
};
const getSub2 = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "/pricing/sub2/all", config);

  return response.data;
};

// subscription 3
const createSub3 = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "/pricing/sub3/post", data, config);

  return response.data;
};
const getSub3 = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "/pricing/sub3/all", config);

  return response.data;
};

// subscription 4
const createSub4 = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "/pricing/sub4/post", data, config);

  return response.data;
};
const getSub4 = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "/pricing/sub4/all", config);

  return response.data;
};

// how to pay

const createHowToPay = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "/pricing/howtopay/post", data, config);

  return response.data;
};
const getHowToPay = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "/pricing/howtopay/all", config);

  return response.data;
};

// how to add coupon

const createHowToCoupon = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "/pricing/howtocoupon/post", data, config);

  return response.data;
};
const getHowToCoupon = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "/pricing/howtocoupon/all", config);

  return response.data;
};

// how to read

const createHowToRead = async (data, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(API_URL + "/pricing/howtoread/post", data, config);

  return response.data;
};
const getHowToRead = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(API_URL + "/pricing/howtoread/all", config);

  return response.data;
};

const pricingService = { createSub1, getSub1, createSub2, getSub2, createSub3, getSub3, createSub4, getSub4, createHowToPay, getHowToPay, createHowToCoupon, getHowToCoupon, createHowToRead, getHowToRead };
export default pricingService;
